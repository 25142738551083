const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '609e73115ca404a318260657',
    authMode: ['providers', 'local'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        {
          realm: 'Cagip',
          clientId: 'vi-cagip',
          buttonText: 'Accès CA-GIP',
        },
      ],
    ],
    authLocalOptions: {
      mode: 'otp',
    },
    disabledComponents: ['wCardsCommentsLabels'],
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    enableExperimentalBlocks: true,
    metadatas: {
      name: `Radio CA-GIP`,
      colorPrimary: '#009597',
      supportEmailAddress: 'support@radio-ca-gip.fr',
    },
    analytics: {
      matomo: '15',
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '61c4657c132f3f00fd5afa4b',
            i18n: {
              fr: {
                title: 'Stratégie CA-GIP',
                description: `Vos podcasts dédiés à la stratégie de CA-GIP`,
              },
            },
            heading: {
              color: '#00003E',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '6512ae7a213a2f792e8cc4bf',
            i18n: {
              fr: {
                title: 'Actualités Tech',
                description: `Les actualités tech de CA-GIP et de son environnement`,
              },
            },
            heading: {
              color: '#06c597',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '62b1922dd7e90c7c1d0ab210',
            i18n: {
              fr: {
                title: 'RSE le podcast',
                description: `Ensemble pour un numérique responsable`,
              },
            },
            heading: {
              color: '#99cc00',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '61c465871fb34f486272dc5a',
            i18n: {
              fr: {
                title: 'Veille Cyber',
                description: `L’essentiel de la veille cyber en 5’ proposé par les équipes Risques IT CA-GIP et Veille Cyber`,
              },
            },
            heading: {
              color: '#65247D',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '63036f2f3dd216f18b5f2bef',
            i18n: {
              fr: {
                title: '3 minutes avec',
                description: `À la rencontre des équipes de CA-GIP, de leurs métiers et de leurs projets`,
              },
            },
            heading: {
              color: '#009597',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '62626a874a7fb87d6826627b',
            i18n: {
              fr: {
                title: 'In&Out',
                description: `Accompagner les experts et non experts dans la compréhension des transformations en cours`,
              },
            },
            heading: {
              color: '#146350',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
